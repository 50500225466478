
export const isMobileSSR = (req) => {
	let ua = req && req.headers && req.headers['user-agent'];
	let check = false;
	(function (a) {
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(a))
			check = true;
	})(ua);
	return check;
};

export const isLoggedInSSR = (req) => {
	let userStatus = false;
	if (req && req.cookies) {
		let _cookie = req.cookies.assumeAuth || req.cookies.authtoken;
		let _localValue = req.cookies.assumeId || req.cookies.isUserLoggedIn;
		userStatus = _localValue && _cookie && !req.cookies.isTMPUser ? _localValue : false;
		userStatus = userStatus !== 'false' ? userStatus : false;
	}
	return userStatus;
};

export const getPreloadImages = (images, key, isArray, key2) => {
	let preloadImages = null;
	if (images) {
		if (isArray) {
			if (images.length > 0) {
				preloadImages = [];
				images.map(imgObj => {
					let image = imgObj && imgObj[key];
					if (image) {
						preloadImages.push(image);
					}
				});
			}
		} else {
			if (images[key]) {
				let image = images[key];
				preloadImages = [];
				preloadImages.push(image);
			}
			if (images[key2]) {
				let image = images[key2];
				preloadImages.push(image);
			}
		}
	}
	return preloadImages;
};

export const httpToHttps = (data) => {
	if (data) {
		try {
			data = JSON.stringify(data);
			data = data.replace(/http:\\\/\\\/img/g, 'https:\\\/\\\/img').replace(/http:\\\/\\\/healthkartdev/g, 'https:\\\/\\\/healthkartdev');
			data = data.replace(/http:\/\/img/g, 'https:\/\/img').replace(/http:\/\/healthkartdev/g, 'https:\/\/healthkartdev');
			data = data.replace(/http:\/\/hkprod.s3.amazonaws.com/g, 'https:\/\/img1.hkrtcdn.com');
			data = data.replace(/https:\/\/hkprod.s3.amazonaws.com/g, 'https:\/\/img1.hkrtcdn.com');
			data = JSON.parse(data);
		} catch (e) {
			console.log("ERROR HTTP TO HTTPS : ", e);
		}
	}
	return data;
};

export const getRequestUrl = (endpoint, isMobile, userId) => {
	let sp = endpoint.indexOf('?') > -1 ? '&' : '?';
	if (userId) {
		sp += `userId=${userId}&`;
	}
	return `${process.env.API_URL}/api${endpoint}${sp}plt=${isMobile ? 2 : 1}&st=${process.env.NEXT_PUBLIC_STORE_ID || 187}`;
};

export const errorResponse = (status, error, isMobile = false, serverIsLoggedin = false) => {
	if (status === 404) {
		return {
			notFound: true,
		};
	} else if (status === 500) {
		console.log('INTERNAL SERVER ERROR: ', error);
		throw new Error('Internal Server Error', error);
	} else {
		return { props: { pageData: null, isMobile, serverIsLoggedin, show404: true } };
	}
};

export const ajaxHelper = async (url, req) => {
	const CACHING_ENABLED = process.env.NEXT_PUBLIC_FROM_PUSH_ENABLE === "true";
	const method = "GET";
	const headers = {};
	if(req && req.url){
		headers.pageuri = req.url.split('?')[0];
	}

	if (CACHING_ENABLED) {
		headers.fromPush = true;
	}
	const options = {
		method,
		headers
	};
	const _pgData = await fetch(url, options);
	const _pageData = await _pgData.json();
	return _pageData;
};

export const updateVDAs = (data, gender_cookie) => {
	const BOY_GIRL_KEY = "gz-gender:boygirl;";
	const BOY_KEY = "gz-gender:boy;";
	const GIRL_KEY = "gz-gender:girl;";
	let result = {};
	if (data) {
		try {
			// updating attr
			if (data.attr) {
				let newAttr = JSON.parse(JSON.stringify(data.attr));
				const genderIndex = data.attr.findIndex(o => o.nm === "gz-gender");
				if (genderIndex > -1) {
					const values = data.attr[genderIndex].values;
					let newValues = [];
					for (let i = 0; i < values.length; i++) {
						const element = values[i];
						if (element.fl_val === "boygirl") {
							continue;
						} else {
							newValues.push(element);
						}
					}
					newAttr[genderIndex].values = newValues;
				}
				result.attr = newAttr;
			}
			// updating selAttr
			if (data.selAttr) {
				let newSelAttr = {};
				const selAttKeys = Object.keys(data.selAttr);
				for (let i = 0; i < selAttKeys.length; i++) {
					const element = selAttKeys[i];
					if (element === "gz-gender" && data.selAttr[element] === "boygirl") {
						newSelAttr[element] = gender_cookie || "boy";
					} else {
						newSelAttr[element] = data.selAttr[element];
					}
				}
				result.selAttr = newSelAttr;
			}
			// updating availVar
			if (data.availVar) {
				let newAvailVar = {};
				const availVarKeys = Object.keys(data.availVar);
				for (let i = 0; i < availVarKeys.length; i++) {
					const keyStr = availVarKeys[i];
					if (keyStr && keyStr.indexOf(BOY_GIRL_KEY) > -1) {
						let newKeyBoy = keyStr.replace(BOY_GIRL_KEY, BOY_KEY);
						let newKeyGirl = keyStr.replace(BOY_GIRL_KEY, GIRL_KEY);
						newAvailVar[newKeyBoy] = data.availVar[keyStr];
						newAvailVar[newKeyGirl] = data.availVar[keyStr];
					} else {
						newAvailVar[keyStr] = data.availVar[keyStr];
					}
				}
				result.availVar = newAvailVar;
			}
		} catch (error) {
			console.log("ERROR UPDATING VDAS :: ", error);
			result = data;
		}
	}
	return result;
};